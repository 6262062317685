var render = function () {
  var _vm$data, _vm$data2, _vm$data3, _vm$data4, _vm$data5, _vm$data$code, _vm$data6, _vm$data$code2, _vm$data7, _vm$data$finalPoin, _vm$data8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center",
      "margin-bottom": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "28px",
      "font-weight": "700",
      "color": "#111"
    }
  }, [_vm._v(" Detail Profil Referral ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "red",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px",
      "is-disabled": _vm.isDisabledResetPoin
    },
    on: {
      "click": _vm.onResetPoin
    }
  }, [_vm._v(" Reset Poin ")])], 1), _c('c-flex', {
    attrs: {
      "background-color": "primary.400",
      "align-items": "center",
      "padding": "20px",
      "border-radius": "12px",
      "gap": "30px",
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "gap": "8px",
      "width": "100%",
      "max-width": "300px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "28px",
      "font-weight": "700",
      "color": "#FFF"
    }
  }, [_vm._v(" Profil User ")]), _c('c-image', {
    attrs: {
      "src": (_vm$data = _vm.data) !== null && _vm$data !== void 0 && _vm$data.photoUrl ? (_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/profile/profile_neutral.webp',
      "width": "120px",
      "height": "120px",
      "border-radius": "100%"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "color": "#FFF"
    }
  }, [_vm._v(" " + _vm._s((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.firstName) + " " + _vm._s((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.lastName) + " ")]), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "500",
      "color": "#FFF"
    }
  }, [_vm._v(" " + _vm._s((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : _vm$data5.email) + " ")])], 1), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "flex-direction": "column",
      "justify-content": "space-around",
      "gap": "30px"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "background-color": "#FFF",
      "padding": "10px 16px",
      "gap": ['4px', '6px'],
      "border-radius": "8px",
      "box-shadow": "2px 2px 10px 0px #00000026",
      "z-index": "2"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": [400, 500],
      "color": "#555"
    }
  }, [_vm._v(" Kode Referral ")]), _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "align-items": "center",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "5px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px', '24px'],
      "font-weight": [500, 700],
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s((_vm$data$code = (_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : _vm$data6.code) !== null && _vm$data$code !== void 0 ? _vm$data$code : '-') + " ")]), _c('input', {
    ref: "referral_code",
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": (_vm$data$code2 = (_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : _vm$data7.code) !== null && _vm$data$code2 !== void 0 ? _vm$data$code2 : '-'
    }
  }), _c('c-flex', {
    attrs: {
      "cursor": "pointer",
      "gap": "6px",
      "font-size": ['12px', '16px'],
      "font-weight": "500",
      "color": "primary.400"
    },
    on: {
      "click": function click($event) {
        return _vm.copyClipboard('referral_code');
      }
    }
  }, [_vm._v(" Salin "), _c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-copy.svg'),
      "width": ['16px', '24px'],
      "height": ['16px', '24px']
    }
  })], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "justify-content": "space-between",
      "align-items": "start",
      "background-color": "secondary.50",
      "padding": "10px 20px",
      "gap": "0px",
      "border-radius": ['8px', '12px'],
      "box-shadow": "0px 2px 8px 0px #00000026"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "500",
      "color": "primary.400"
    }
  }, [_vm._v(" Jumlah Poin ")]), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "align-items": "center",
      "gap": ['10px', '30px']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-points.svg'),
      "height": "auto",
      "width": ['40px', '60px']
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s((_vm$data$finalPoin = (_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : _vm$data8.finalPoin) !== null && _vm$data$finalPoin !== void 0 ? _vm$data$finalPoin : 0) + " Poin ")])], 1)], 1)], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": _vm.showModalResetPoin,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "125px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image get program"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$$v$formResetPoin$, _vm$$v$formResetPoin$2;

        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "24px 0",
            "flex-direction": "column",
            "gap": "16px"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "20px",
            "size-desktop": "24px",
            "color": "primary.400",
            "text-align": "center",
            "padding-inline": "16px"
          }
        }, [_vm._v(" Pilih alasan reset poin,"), _c('br'), _vm._v(" alasan akan ditampilkan pada notifikasi user ")]), _c('c-box', {
          attrs: {
            "padding-inline": "32px"
          }
        }, [_c('c-form-control', {
          attrs: {
            "is-required": "",
            "is-invalid": (_vm$$v$formResetPoin$ = _vm.$v.formResetPoin.note) === null || _vm$$v$formResetPoin$ === void 0 ? void 0 : _vm$$v$formResetPoin$.$invalid
          }
        }, [_c('c-form-label', {
          attrs: {
            "font-size": ['14px', '16px'],
            "color": "primary.400",
            "font-weight": "400",
            "font-family": "Roboto",
            "padding-left": "8px"
          }
        }, [_vm._v(" Alasan Reset ")]), _c('c-input-group', {
          attrs: {
            "size": "md"
          }
        }, [_c('c-select', {
          attrs: {
            "placeholder": "Pilih alasan reset",
            "height": ['48px', '62px'],
            "font-size": ['14px', '18px'],
            "border-radius": ['8px'],
            "is-invalid": false
          },
          model: {
            value: _vm.formResetPoin.note,
            callback: function callback($$v) {
              _vm.$set(_vm.formResetPoin, "note", $$v);
            },
            expression: "formResetPoin.note"
          }
        }, _vm._l(_vm.listReasons, function (item, i) {
          return _c('option', {
            key: i,
            domProps: {
              "value": item === null || item === void 0 ? void 0 : item.value
            }
          }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.label) + " ")]);
        }), 0)], 1)], 1), _vm.formResetPoin.note === 'Lainnya' ? _c('c-form-control', {
          attrs: {
            "is-required": "",
            "mt": ['12px', '16px'],
            "is-invalid": (_vm$$v$formResetPoin$2 = _vm.$v.formResetPoin.otherNote) === null || _vm$$v$formResetPoin$2 === void 0 ? void 0 : _vm$$v$formResetPoin$2.$invalid
          }
        }, [_c('c-input-group', {
          attrs: {
            "size": "md"
          }
        }, [_c('c-input', {
          attrs: {
            "type": "text",
            "error-border-color": "red.300",
            "placeholder": "Masukkan alasan lainnya",
            "height": ['48px', '62px'],
            "font-size": ['14px', '18px'],
            "border-radius": ['8px']
          },
          model: {
            value: _vm.formResetPoin.otherNote,
            callback: function callback($$v) {
              _vm.$set(_vm.formResetPoin, "otherNote", $$v);
            },
            expression: "formResetPoin.otherNote"
          }
        })], 1)], 1) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "8px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoading
          },
          on: {
            "click": _vm.onCloseConfirm
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "is-disabled": _vm.isDisabledSubmitResetPoin
          },
          on: {
            "click": _vm.onConfirmResetPoin
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.showModalConfirmResetPoin,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "125px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image get program"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$data9, _vm$data10;

        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "16px 24px",
            "flex-direction": "column"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400",
            "text-align": "center"
          }
        }, [_vm._v(" Apakah kamu yakin ingin reset poin akun " + _vm._s((_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : _vm$data9.firstName) + " " + _vm._s((_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : _vm$data10.lastName) + " dengan alasan " + _vm._s(_vm.formResetPoin.note == 'Lainnya' ? _vm.formResetPoin.otherNote : _vm.formResetPoin.note) + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "8px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoading
          },
          on: {
            "click": _vm.onCloseConfirmResetPoin
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "is-loading": _vm.isLoading
          },
          on: {
            "click": _vm.onSubmitResetPoin
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }