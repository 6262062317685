var render = function () {
  var _vm$clientsUse, _vm$clientsUse2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Referral',
        href: '/admin/referrals',
        isCurrent: false
      }, {
        label: 'Detail Profil Referral',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '20px'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('DetailProfile', {
    attrs: {
      "data": _vm.client
    },
    on: {
      "refresh": _vm.reInit
    }
  }), _c('ClientsUse', {
    attrs: {
      "items": (_vm$clientsUse = _vm.clientsUse) === null || _vm$clientsUse === void 0 ? void 0 : _vm$clientsUse.rows,
      "total": (_vm$clientsUse2 = _vm.clientsUse) === null || _vm$clientsUse2 === void 0 ? void 0 : _vm$clientsUse2.count,
      "per-page": _vm.perPage,
      "page": _vm.page
    }
  }), _c('ListRedeem', {
    attrs: {
      "items": _vm.redeemList
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }