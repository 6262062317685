<template>
  <c-box
    margin-bottom="30px"
  >
    <c-flex
      width="100%"
      justify-content="space-between"
      align-items="center"
      margin-bottom="20px"
    >
      <c-text
        font-size="28px"
        font-weight="700"
        color="#111"
      >
        Pengguna Kode
      </c-text>
      <c-text
        font-size="20px"
        font-weight="700"
        color="primary.400"
      >
        {{ total ?? 0 }} Orang
      </c-text>
    </c-flex>
    <c-box
      v-if="items.length > 0"
    >
      <c-grid
        width="100%"
        template-columns="repeat(2, 1fr)"
        gap="16px"
      >
        <c-flex
          v-for="(item, i) in items"
          :key="i"
          box-shadow="2px 2px 15px 0px #00000026"
          padding="16px 12px"
          gap="8px"
          border-radius="12px"
          align-items="center"
        >
          <c-flex
            flex-grow="1"
            gap="12px"
            align-items="center"
          >
            <c-image
              :src="item?.photoUrl ? item?.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/profile/profile_neutral.webp'"
              width="50px"
              height="50px"
              border-radius="100%"
            />
            <c-flex
              flex-direction="column"
              justify-content="space-between"
            >
              <c-text
                font-size="18px"
                font-weight="500"
                color="#111"
              >
                {{ item?.email }}
              </c-text>
              <c-text
                font-size="16px"
                font-weight="400"
                color="#333"
              >
                {{ item?.dateString }}            
              </c-text>
            </c-flex>
          </c-flex>
          <c-text
            flex-shrink="0"
            color="primary.400"
            font-size="14px"
            font-weight="400"
          >
            +{{ item?.point ?? 0 }} Poin
          </c-text>
        </c-flex>
      </c-grid>
      <c-flex
        width="100%"
        padding-top="30px"
        justify-content="center"
      >
        <BasePagination
          :page="page"
          :total-page="totalPage"
          @on-change="onChangePage"
        />
      </c-flex>
    </c-box>
    <c-flex
      v-else
      flex-direction="column"
      py="30px"
      justify-content="center"
      align-items="center"
    >
      <c-image
        :src="require('@/assets/empty-nutritionists.svg')"
        alt="empty"
        :width="['180px', '200px']"
        margin-bottom="16px"
      />
      <c-text 
        font-family="Roboto" 
        :font-size="['16px', '18px']" 
        font-weight="500"
      > 
        Pengguna Kode tidak ditemukan
      </c-text>
    </c-flex>
  </c-box>
</template>

<script>
import { formatDateV2 } from '@/utils/format-date'
import BasePagination from '@/components/elements/base-pagination.vue'

export default {
  name: 'AdminReferralsClientUse',
  components: {
    BasePagination,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    total: {
      type: [String, Number],
      default: 0,
    },
    perPage: {
      type: [Number],
      default: 6,  
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.perPage)
    },
  },
  methods: {
    formatDateV2,
    onChangePage(page) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: page,
        },
        params: { savePosition: true },
      })
    },
  },
}
</script>

<style>

</style>