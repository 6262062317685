<template>
  <c-box
    width="100%"
  >
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        {
          label: 'Manajemen Referral',
          href: '/admin/referrals',
          isCurrent: false
        },
        {
          label: 'Detail Profil Referral',
          isCurrent: true
        },
      ]"
    />
    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '20px']"
      :min-height="['unset', '74vh']"
    >
      <DetailProfile
        :data="client"
        @refresh="reInit"
      />
      <ClientsUse
        :items="clientsUse?.rows"
        :total="clientsUse?.count"
        :per-page="perPage"
        :page="page"
      />
      <ListRedeem
        :items="redeemList"
      />
    </c-box>
  </c-box>
</template>

<script>
import { reqReferral } from '@/requests/dietela-api/referral'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import DetailProfile from '@/views/admin/referrals/_views/detail-profile.vue'
import ClientsUse from '@/views/admin/referrals/_views/clients-use.vue'
import ListRedeem from '@/views/admin/referrals/_views/list-redeem.vue'

export default {
  name: 'AdminReferralsDetail',
  components: {
    BreadcrumbPath,
    DetailProfile,
    ClientsUse,
    ListRedeem,
  },
  data() {
    return {
      client: null,
      clientsUse: {
        count: 0,
        rows: [],
      },
      redeemList: [],
      perPage: 6,
      page: 1,
      isLoading: false,
    }
  },
  computed: {
    clientId() {
      return this.$route.params.id
    }, 
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.init()
      },
    },
  },
  mounted() {},
  methods: {
    async init() {
      this.isLoading = true

      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
      this.perPage = this.$route.query.perpage ?? 6
      
      try {
        const res = await reqReferral.detailClientReferral(this.$store.getters.axios, { page: this.page, perpage: this.perPage, clientId: this.clientId })
        
        this.client = {
          code: res.data?.data?.code,
          email: res.data?.data?.email,
          finalPoin: res.data?.data?.finalPoin,
          firstName: res.data?.data?.firstName,
          lastName: res.data?.data?.lastName,
          photoUrl: res.data?.data?.photoUrl,
        }
        this.clientsUse.count = res.data.data?.clientsUse?.count ?? 0
        this.clientsUse.rows = res.data.data?.clientsUse?.rows ?? []
        this.redeemList = res.data.data?.redeemList ?? []
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
        })
      }
      this.isLoading = false
    },
    reInit() {
      this.init()
    },
  },
}
</script>

<style>

</style>