var render = function () {
  var _vm$total;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center",
      "margin-bottom": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "28px",
      "font-weight": "700",
      "color": "#111"
    }
  }, [_vm._v(" Pengguna Kode ")]), _c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s((_vm$total = _vm.total) !== null && _vm$total !== void 0 ? _vm$total : 0) + " Orang ")])], 1), _vm.items.length > 0 ? _c('c-box', [_c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": "repeat(2, 1fr)",
      "gap": "16px"
    }
  }, _vm._l(_vm.items, function (item, i) {
    var _item$point;

    return _c('c-flex', {
      key: i,
      attrs: {
        "box-shadow": "2px 2px 15px 0px #00000026",
        "padding": "16px 12px",
        "gap": "8px",
        "border-radius": "12px",
        "align-items": "center"
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-grow": "1",
        "gap": "12px",
        "align-items": "center"
      }
    }, [_c('c-image', {
      attrs: {
        "src": item !== null && item !== void 0 && item.photoUrl ? item === null || item === void 0 ? void 0 : item.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/profile/profile_neutral.webp',
        "width": "50px",
        "height": "50px",
        "border-radius": "100%"
      }
    }), _c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "justify-content": "space-between"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "18px",
        "font-weight": "500",
        "color": "#111"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.email) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "400",
        "color": "#333"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.dateString) + " ")])], 1)], 1), _c('c-text', {
      attrs: {
        "flex-shrink": "0",
        "color": "primary.400",
        "font-size": "14px",
        "font-weight": "400"
      }
    }, [_vm._v(" +" + _vm._s((_item$point = item === null || item === void 0 ? void 0 : item.point) !== null && _item$point !== void 0 ? _item$point : 0) + " Poin ")])], 1);
  }), 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "padding-top": "30px",
      "justify-content": "center"
    }
  }, [_c('BasePagination', {
    attrs: {
      "page": _vm.page,
      "total-page": _vm.totalPage
    },
    on: {
      "on-change": _vm.onChangePage
    }
  })], 1)], 1) : _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "py": "30px",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/empty-nutritionists.svg'),
      "alt": "empty",
      "width": ['180px', '200px'],
      "margin-bottom": "16px"
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '18px'],
      "font-weight": "500"
    }
  }, [_vm._v(" Pengguna Kode tidak ditemukan ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }