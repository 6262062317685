<template>
  <c-box>
    <c-flex
      width="100%"
      justify-content="space-between"
      align-items="center"
      margin-bottom="20px"
    >
      <c-text
        font-size="28px"
        font-weight="700"
        color="#111"
      >
        Riwayat Penukaran Poin
      </c-text>
    </c-flex>

    <c-box
      v-if="items.length > 0"
    >
      <c-flex
        v-for="key in Object.keys(itemsCategorized)"
        :key="key"
        width="100%"
        flex-direction="column"
        :gap="['4px', '8px']"
        :px="['8px', '0']"
        py="8px"
      >
        <c-text
          :font-size="['12px', '16px']" 
          font-weight="400"
          color="#333"
        >
          {{ formatDateV2(key) }}
        </c-text>
        <c-grid
          width="100%"
          :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
          :gap="['12px', '24px']"
          background-color="#FFF"
        >
          <c-flex
            v-for="(item, i) in itemsCategorized[key]"
            :key="i"
            width="100%"
            flex-direction="column"
            :gap="['4px', '8px']"
          >
            <c-flex
              width="100%"
              flex-direction="row"
              align-items="center"
              background-color="#FFF"
              :border-radius="['8px', '8px']"
              :gap="['8px', '16px']"
              :padding="['8px', '12px']"
              :box-shadow="['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026']"
              overflow="hidden"
            >
              <c-flex
                flex-shrink="0"
                position="relative"
                align-items="center"
                justify-content="center"
                :width="['90px', '120px']"
                height="100%"
                border-radius="8px"
                padding="4px"
                :background-color="getItemBackgroundColor(item)"
              >
                <c-image
                  :src="getItemImage(item?.type)" 
                  width="auto"
                  :height="['42px', '56px']"
                  margin-inline="auto"
                  object-fit="cover"
                  :border-radius="['4px', '8px']"
                />
              </c-flex>
              <c-flex
                flex-grow="1"
                flex-direction="column"
                justify-content="space-between"
                height="100%"
                gap="0px"
              >
                <c-flex
                  flex-direction="row"
                  align-items="flex-start"
                  justify-content="space-between"
                  gap="8px"
                >
                  <c-text
                    :font-size="['14px', '18px']"
                    font-weight="500"
                    color="#111"
                  >
                    {{ getItemTitle(item) }}
                  </c-text>
                  <c-text
                    v-if="item?.type == 'redeem'"
                    :font-size="['14px', '18px']"
                    font-weight="500"
                    :color="item?.status == 'failed' ? '#333' : 'danger.400'"
                    flex-shrink="0"
                  >
                    {{ item?.status == 'failed' ? item?.totalPrice : `-${item?.totalPrice}` }} Poin
                  </c-text>
                  <c-text
                    v-else
                    :font-size="['14px', '18px']"
                    font-weight="500"
                    :color="item?.poin <= 0 ? 'danger.400' : 'primary.400'"
                    flex-shrink="0"
                  >
                    {{ item?.poin > 0 ? `+${item?.poin}` : item?.poin }} Poin
                  </c-text>
                </c-flex>
                <c-flex
                  flex-direction="row"
                  align-items="flex-end"
                  justify-content="space-between"
                >
                  <c-text
                    v-if="item?.type == 'redeem'"
                    :color="item?.status == 'success' ? 'primary.400' : (item?.status == 'failed' ? 'danger.400' : 'warning.400') "
                    :font-size="['12px', '16px']"
                    font-weight="500"
                  >
                    {{ onCheckStatus(item?.status) }}
                  </c-text>
                  <c-text
                    v-else-if="item?.type == 'reset-poin' || item?.type == 'expired'"
                    color="#111"
                    :font-size="['12px', '16px']"
                    font-weight="500"
                  >
                    {{ item?.note }}
                  </c-text>
                  <c-text
                    v-else
                    color="primary.400"
                    :font-size="['12px', '16px']"
                    font-weight="500"
                  >
                    Berhasil
                  </c-text>
                  <c-text
                    color="#333"
                    :font-size="['12px', '16px']"
                  >
                    {{ formatDateV2(item?.createdAt, 4) }}
                  </c-text>
                </c-flex>
              </c-flex>
            </c-flex>
          </c-flex>
        </c-grid>
      </c-flex>
    </c-box>
    <c-flex
      v-else
      flex-direction="column"
      py="30px"
      justify-content="center"
      align-items="center"
    >
      <c-image
        :src="require('@/assets/empty-nutritionists.svg')"
        alt="empty"
        :width="['180px', '200px']"
        margin-bottom="16px"
      />
      <c-text 
        font-family="Roboto" 
        :font-size="['16px', '18px']" 
        font-weight="500"
      > 
        Riwayat Penukaran Poin tidak ditemukan
      </c-text>
    </c-flex>
    <c-flex
      v-if="total > 0"
      width="100%"
      padding-top="30px"
      justify-content="center"
    >
      <BasePagination
        :page="page"
        :total-page="totalPage"
        @on-change="onChangePage"
      />
    </c-flex>
  </c-box>
</template>

<script>
import { reqReferral } from '@/requests/dietela-api/referral'
import { formatDateV2 } from '@/utils/format-date'
import BasePagination from '@/components/elements/base-pagination.vue'

export default {
  name: 'AdminReferralsListRedeem',
  components: {
    BasePagination,
  },
  data() {
    return {
      items: [],
      itemsCategorized: [],
      perPage: 10,
      page: 1,
      total: 0,
      isLoading: false,
    }
  },
  computed: {
    clientId() {
      return this.$route.params.id
    }, 
    totalPage() {
      return Math.ceil(this.total / this.perPage)
    },
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.length > 0) {
          const categorizedData = {}
          for (const item of val) {
            const date = item?.createdAt.split(' ')[0] // Extract YYYY-MM-DD
            if (!categorizedData[date]) {
              categorizedData[date] = []
            }
            categorizedData[date].push(item)
          }
          this.itemsCategorized = categorizedData
        }
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    formatDateV2,
    async init() {
      this.isLoading = true
      try {
        const res = await reqReferral.poinHistory(this.$store.getters.axios, { page: this.page, perpage: this.perPage, clientId: this.clientId })
        this.items = res.data.data?.rows
        this.total = res.data.data?.count
      } catch (err) {
        this.$toast({
          title: `${err?.response?.status} Failed!`,
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 500,
          position: 'bottom-right',
        })
      }
      this.isLoading = false
    },
    onChangePage(page) {
      if (this.page != page && page > 0) {
        this.page = page
        this.init()
      }
    },
    onCheckStatus(status) {
      switch (status) {
        case 'success':
          return 'Berhasil'
        case 'failed':
          return 'Gagal'
        case 'inprogress':
          return 'Dalam Proses'
        default:
          return '-'
      }
    },
    getItemBackgroundColor(item) {
      switch (item?.type) {
        case 'earn-poin':
          return 'primary.50'
        case 'reset-poin':
          return 'danger.50'
        case 'extra-poin':
          return 'primary.50'
        case 'redeem':
          return item?.status == 'success' ? 'primary.50' : (item?.status == 'failed' ? 'danger.50' : 'warning.50')
        case 'refund':
          return 'primary.50'
        case 'expired':
          return 'danger.50'
        default:
          return 'transparent'
      }
    },
    getItemImage(type) {
      switch (type) {
        case 'earn-poin':
          return require('@/assets/icons/earn-poin.png')
        case 'reset-poin':
          return require('@/assets/icons/reset-poin.png')
        case 'extra-poin':
          return require('@/assets/icons/extra-poin.png')
        case 'redeem':
          return require('@/assets/icons/redeem.png')
        case 'refund':
          return require('@/assets/icons/refund-poin.png')
        case 'expired':
          return require('@/assets/icons/expired-poin.png')
        default:
          return require('@/assets/icons/icon-points.svg')
      }
    },
    getItemTitle(item) {
      switch (item?.type) {
        case 'redeem':
          return `Penukaran ${item?.giftName}`
        case 'earn-poin':
          return 'Berhasil Ajak Teman'
        case 'reset-poin':
          return 'Poin Hangus'
        case 'extra-poin':
          return item?.note || 'Target Bonus Tercapai'
        case 'refund':
          return 'Pengembalian Poin'
        case 'expired':
          return 'Poin Kadaluarsa'
        default:
          return item?.type
      }
    },
  }, 
}
</script>

<style>

</style>