<template>
  <c-box
    margin-bottom="30px"
  >
    <c-flex
      width="100%"
      justify-content="space-between"
      align-items="center"
      margin-bottom="20px"
    >
      <c-text
        font-size="28px"
        font-weight="700"
        color="#111"
      >
        Detail Profil Referral
      </c-text>
      <c-button
        variant="solid"
        variant-color="red"
        border-radius="60px"
        min-w="114px"
        h="auto"
        py="13px"
        px="18px"
        font-size="16px"
        font-weight="500"
        line-height="24px"
        :is-disabled="isDisabledResetPoin"
        @click="onResetPoin"
      >
        Reset Poin
      </c-button>
    </c-flex>
    <c-flex 
      background-color="primary.400"
      align-items="center"
      padding="20px"
      border-radius="12px"
      gap="30px"
      width="100%"
    >
      <c-flex
        flex-direction="column"
        align-items="center"
        gap="8px"
        width="100%"
        max-width="300px"
      >
        <c-text
          font-size="28px"
          font-weight="700"
          color="#FFF"
        >
          Profil User
        </c-text>
        <c-image
          :src="data?.photoUrl ? data?.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/profile/profile_neutral.webp'"
          width="120px"
          height="120px"
          border-radius="100%"
        />
        <c-text
          font-size="18px"
          font-weight="500"
          color="#FFF"
        >
          {{ data?.firstName }} {{ data?.lastName }}          
        </c-text>
        <c-text
          font-size="16px"
          font-weight="500"
          color="#FFF"
        >
          {{ data?.email }}
        </c-text>
      </c-flex>
      <c-flex
        flex-grow="1"
        flex-direction="column"
        justify-content="space-around"
        gap="30px"
      >
        <c-flex 
          width="100%"
          flex-direction="column"
          background-color="#FFF"
          padding="10px 16px"
          :gap="['4px', '6px']"
          border-radius="8px"
          box-shadow="2px 2px 10px 0px #00000026"
          z-index="2"
        >
          <c-text
            :font-size="['12px', '16px']"
            :font-weight="[400, 500]"
            color="#555"
          >
            Kode Referral
          </c-text>
          <c-flex
            justify-content="space-between"
            align-items="center"
            border-bottom="1px solid #C4C4C4"
            padding-bottom="5px"
          >
            <c-text
              :font-size="['18px', '24px']"
              :font-weight="[500, 700]"
              color="primary.400"
            >
              {{ data?.code ?? '-' }}
            </c-text>
            <input
              ref="referral_code"
              type="hidden"
              :value="data?.code ?? '-'"
            >
            <c-flex
              cursor="pointer"
              gap="6px"
              :font-size="['12px', '16px']"
              font-weight="500"
              color="primary.400"
              @click="copyClipboard('referral_code')"
            >
              Salin
              <c-image
                :src="require('@/assets/icons/icon-copy.svg')"
                :width="['16px', '24px']"
                :height="['16px', '24px']"
              />
            </c-flex>
          </c-flex>
        </c-flex>
        <c-flex
          width="100%"
          flex-direction="column"
          justify-content="space-between"
          align-items="start"
          background-color="secondary.50"
          padding="10px 20px"
          gap="0px"
          :border-radius="['8px', '12px']"
          box-shadow="0px 2px 8px 0px #00000026"
        >
          <c-text
            font-size="16px"
            font-weight="500"
            color="primary.400"
          >
            Jumlah Poin
          </c-text>
          <c-flex
            flex-grow="1"
            align-items="center"
            :gap="['10px', '30px']"
          >
            <c-image
              :src="require('@/assets/icons/icon-points.svg')"
              height="auto"
              :width="['40px', '60px']"
            />
            <c-text
              font-size="20px"
              font-weight="700"
            >
              {{ data?.finalPoin ?? 0 }} Poin
            </c-text>
          </c-flex>
        </c-flex>
      </c-flex>
    </c-flex>

    <BaseModal
      :is-open="showModalResetPoin"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="125px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="24px 0"
          flex-direction="column"
          gap="16px"
        >
          <BaseText
            size-mobile="20px"
            size-desktop="24px"
            color="primary.400"
            text-align="center"
            padding-inline="16px"
          >
            Pilih alasan reset poin,<br>
            alasan akan ditampilkan pada notifikasi user
          </BaseText>

          <c-box
            padding-inline="32px"
          >
            <c-form-control
              is-required
              :is-invalid="$v.formResetPoin.note?.$invalid"
            >
              <c-form-label
                :font-size="['14px', '16px']"
                color="primary.400"
                font-weight="400"
                font-family="Roboto"
                padding-left="8px"
              >
                Alasan Reset
              </c-form-label>
              <c-input-group size="md">
                <c-select
                  v-model="formResetPoin.note"
                  placeholder="Pilih alasan reset"
                  :height="['48px', '62px']"
                  :font-size="['14px', '18px']"
                  :border-radius="['8px']"
                  :is-invalid="false"
                >
                  <option
                    v-for="(item, i) in listReasons"
                    :key="i"
                    :value="item?.value"
                  >
                    {{ item?.label }}
                  </option>
                </c-select>
              </c-input-group>
            </c-form-control>
            <c-form-control
              v-if="formResetPoin.note === 'Lainnya'"
              is-required
              :mt="['12px', '16px']"
              :is-invalid="$v.formResetPoin.otherNote?.$invalid"
            >
              <c-input-group
                size="md"
              >
                <c-input
                  v-model="formResetPoin.otherNote"
                  type="text"
                  error-border-color="red.300"
                  placeholder="Masukkan alasan lainnya"
                  :height="['48px', '62px']"
                  :font-size="['14px', '18px']"
                  :border-radius="['8px']"
                />
              </c-input-group>
            </c-form-control>
          </c-box>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="8px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoading"
            @click="onCloseConfirm"
          >
            Batal
          </BaseButton>
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            :is-disabled="isDisabledSubmitResetPoin"
            @click="onConfirmResetPoin"
          >
            Simpan
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="showModalConfirmResetPoin"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="125px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="16px 24px"
          flex-direction="column"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
            text-align="center"
          >
            Apakah kamu yakin ingin reset poin akun {{ data?.firstName }} {{ data?.lastName }}
            dengan alasan {{ formResetPoin.note == 'Lainnya' ? formResetPoin.otherNote : formResetPoin.note }}
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="8px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoading"
            @click="onCloseConfirmResetPoin"
          >
            Batal
          </BaseButton>
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            :is-loading="isLoading"
            @click="onSubmitResetPoin"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseText from '@/components/elements/base-text.vue'
import { reqReferral } from '@/requests/dietela-api/referral'

export default {
  name: 'AdminReferralsDetailProfile',
  components: {
    BaseModal,
    BaseButton,
    BaseText,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'refresh',
  ],
  data() {
    return {
      showModalResetPoin: false,
      showModalConfirmResetPoin: false,
      formResetPoin: {
        note: '',
        otherNote: '',
      },
      isLoading: false,
    }
  },
  computed: {
    _clientId() {
      return this.$route.params.id
    },
    listReasons() {
      return [
        { label: 'Terindikasi Kecurangan', value: 'Terindikasi Kecurangan' },
        { label: 'Lainnya', value: 'Lainnya' },
      ]
    },
    isDisabledSubmitResetPoin() {
      return this.$v.$invalid
    },
    isDisabledResetPoin() {
      if (this.data?.finalPoin && this.data?.finalPoin > 0) {
        return false
      }
      return true
    },
  },
  validations: {
    formResetPoin: {
      note: { required },
      otherNote: { required: requiredIf((model) => model.note == 'Lainnya') },
    },
  },
  methods: {
    onResetPoinForm() {
      this.formResetPoin.note = ''
      this.formResetPoin.otherNote = ''
    },
    onResetPoin() {
      this.onResetPoinForm()
      this.showModalResetPoin = true
    },
    onCloseConfirm() {
      if (!this.isLoading) {
        this.showModalResetPoin = false
      }
    },
    onConfirmResetPoin() {
      this.onCloseConfirm()
      this.showModalConfirmResetPoin = true
    },
    onCloseConfirmResetPoin() {
      if (!this.isLoading) {
        this.showModalConfirmResetPoin = false
      }
    },
    async onSubmitResetPoin() {
      try {
        this.isLoading = true
        await reqReferral.resetPoin(
          this.$store.getters.axios, {
            clientId: this._clientId,
            note: this.formResetPoin.note == 'Lainnya' ? this.formResetPoin.otherNote : this.formResetPoin.note,
          }, 
        )
        
        this.$emit('refresh')
        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'Berhasil reset poin',
          duration: 2000,
        })
      } catch (err) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: err?.response?.data?.message || 'Something went wrong!',
          duration: 3000,
        })
      } finally {
        this.isLoading = false
        this.onCloseConfirmResetPoin()
      }
    },
    copyClipboard(ref) {
      const el = this.$refs[ref]
      try {
        el.setAttribute('type', 'text')
        el.select()
        document.execCommand('copy')
        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'Berhasil disalin',
          duration: 2000,
        })
        this.isShowSteps = false
      } catch (err) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: 'Oops, unable to copy',
          duration: 2000,
        })
      }
      el.setAttribute('type', 'hidden')
    },
  },
}
</script>

<style>

</style>